import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Container from '../../components/Container';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import { Post } from '../../types/Post';

const StyledH2 = styled.h2`
  margin: 0 0 1rem 0;
  font-size: 2rem;
  text-align: center;

  @media (min-width: 426px) {
    margin: 0 1rem 0 0;
    text-align: left;
  }
`;

const StyledTime = styled.time`
  font-size: 0.75rem;
  color: #444444;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  flex-direction: column;

  @media (min-width: 426px) {
    flex-direction: row;
  }
`;

const StyledContainer = styled(Container)<{ flexDirection?: string }>`
  max-width: 570px;

  a:hover {
    text-decoration: underline;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

const Content = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  p {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  ul,
  ol {
    margin: 0 0 1rem 0;
  }

  ul li,
  ol li {
    margin-bottom: 0.5rem;
  }

  ul li::marker,
  ol li::marker {
    color: #444444;
  }
`;

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <OutboundLink
        href={node.data.uri}
        target="_blank"
        rel={
          node.data.uri.startsWith('https://amzn.to')
            ? 'sponsored nofollow noreferrer'
            : 'nofollow noreferrer'
        }
      >
        {children}
      </OutboundLink>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = getImage(node.data.target.localFile);

      return (
        <ImageContainer>
          <GatsbyImage image={image} alt="" />
        </ImageContainer>
      );
    },
  },
};

type Props = {
  data: {
    contentfulBlogPost: Post;
  };
};

const BlogPost = ({ data: { contentfulBlogPost } }: Props) => {
  const image = getImage(contentfulBlogPost.heroImage.localFile);

  return (
    <Layout>
      <SEO
        title={contentfulBlogPost.title}
        image={contentfulBlogPost.heroImage.file.url}
        description={contentfulBlogPost.metaDescription}
        uri={contentfulBlogPost.slug}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://google.com/article',
            },
            headline: contentfulBlogPost.title,
            image: [contentfulBlogPost.heroImage.file.url],
            datePublished: contentfulBlogPost.publishDate,
            dateModified: contentfulBlogPost.updatedAt,
            author: {
              '@type': 'Person',
              name: 'Elle',
            },
            publisher: {
              '@type': 'Organization',
              name: 'Bare Faced by Elle',
              logo: {
                '@type': 'ImageObject',
                url: 'https://barefacedbyelle.co.uk/images/logo.png',
              },
            },
          })}
        </script>
      </SEO>
      <StyledContainer flexDirection="column">
        <Header>
          <StyledH2>{contentfulBlogPost.title}</StyledH2>
          <StyledTime>
            {format(new Date(contentfulBlogPost.publishDate), 'd/L/y')}
          </StyledTime>
        </Header>
        <ImageContainer>
          <GatsbyImage image={image} alt="" />
        </ImageContainer>
        <Content>
          {renderRichText(contentfulBlogPost.richBody, options)}
        </Content>
      </StyledContainer>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      publishDate
      richBody {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            localFile {
              childImageSharp {
                gatsbyImageData(width: 570, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
      metaDescription
      heroImage {
        file {
          url
        }
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 570, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      updatedAt
    }
  }
`;

export default BlogPost;
